/*
 * On IE8, Ember tries to log some stuff and since console.log
 * is not defined when the dev tools are closed, the app never
 * gets to startup.
 *
 * This works it around.
 */
if(!window.console) {
  window.console = {};
  console.log = function () {};
}
