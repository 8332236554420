define('ember-remarkable/components/md-text', ['exports', 'ember', 'remarkable', 'hljs'], function (exports, _ember, _remarkable, _hljs) {
  'use strict';

  var computed = _ember['default'].computed;
  var HTMLBars = _ember['default'].HTMLBars;

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    text: '',
    typographer: false,
    linkify: false,
    html: false,
    extensions: true,
    dynamic: false,

    parsedMarkdownUnsafe: computed('text', 'html', 'typographer', 'linkify', function () {
      var md = new _remarkable['default']({
        typographer: this.get('typographer'),
        linkify: this.get('linkify'),
        html: this.get('html'),

        highlight: function highlight(str, lang) {
          if (lang === 'text' || lang === 'no-highlight') {
            return '';
          }

          if (lang && _hljs['default'].getLanguage(lang)) {
            try {
              return _hljs['default'].highlight(lang, str).value;
            } catch (err) {}
          }

          try {
            return _hljs['default'].highlightAuto(str).value;
          } catch (err) {}

          return '';
        }
      });

      if (this.get('extensions')) {
        md.core.ruler.enable(['abbr']);
        md.block.ruler.enable(['footnote', 'deflist']);
        md.inline.ruler.enable(['footnote_inline', 'ins', 'mark', 'sub', 'sup']);
      }

      return md.render(this.get('text'));
    }),

    parsedMarkdown: computed('parsedMarkdownUnsafe', function () {
      var parsedMarkdownUnsafe = this.get('parsedMarkdownUnsafe');
      return new _ember['default'].Handlebars.SafeString(parsedMarkdownUnsafe);
    }),

    precompiledTemplate: computed('parsedMarkdownUnsafe', function () {
      var parsedMarkdownUnsafe = this.get('parsedMarkdownUnsafe');
      return HTMLBars.compile(parsedMarkdownUnsafe, false);
    })
  });
});